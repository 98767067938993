import Vue from 'vue'
import Vuex from 'vuex'
import { getChooseImage, setChooseImage, removeChooseImage } from "@/store/cookie";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    doctorRegData: {
      doctorIntroduction: "",
      doctorGoodat: "",
      doctorThumb: "",
      IDCard: "",
      mobile: "",
      name: "",
      IDCardA: "",
      IDCardB: "",
      zcz: '',
      zyzA: '',
      zyzB: '',
      yszA: '',
      yszB: '',
    },
    uplodaState: getChooseImage() ? false : true,
  },
  getters: {
    stateData(state) {
      return state.doctorRegData
    },
    uplodaStateData(state) {
      return state.uplodaState
    }
  },
  mutations: {
    editDoctorRegData(state, value) {
      state.doctorRegData = value
      console.log(">>>>", state.doctorRegData)
    },
    editUplodaStateData(state, value) {
      state.uplodaState = value
      console.log(">>>>", state.uplodaState)
    }
  },
  actions: {
    changeUplodaStateData(context, value) {
      context.commit('editUplodaStateData', value);
    }
  },
  modules: {
  }
})
