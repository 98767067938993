<template>
  <div class="component-upload-image" :id="styleType" @click="uplodaClick">
    <el-upload accept="image/*" :action="uploadImgUrl" list-type="picture-card" :disabled="getDisibleState" :on-success="handleUploadSuccess" :id="styleType" :class="'upload-resource ' + styleType" :before-upload="handleBeforeUpload" :on-error="handleUploadError" name="file" :show-file-list="false" :headers="headers" style="display: inline-block; vertical-align: top" v-model="Addfrom.url" :http-request="httpRequest" :multiple="false" :limit="1">
      <div class="flex-column flex-align-center image-slot">
        <!-- <i class="el-icon-plus" /> -->
        <img v-if="value" :src="value" class="id-img" />
        <template v-if="!value">
          <img class="id-img" v-if="styleType == 'IDCardA'" src="../../assets/recruit/sfz_zm@3x.png" />
          <img class="id-img" v-if="styleType == 'IDCardB'" src="../../assets/recruit/sfz_fm@3x.png" />
          <img class="id-img" v-if="styleType == 'doctorThumb'" src="../../assets/recruit/touxiang@3x.png" />
          <img class="id-img" v-if="styleType == 'zyzA'" src="../../assets/recruit/zyz_shouye@3x.png" />
          <img class="id-img" v-if="styleType == 'zyzB'" src="../../assets/recruit/zyz_neiye@3x.png" />
          <img class="id-img" v-if="styleType == 'yszA'" src="../../assets/recruit/zgz_shouye@3x.png" />
          <img class="id-img" v-if="styleType == 'yszB'" src="../../assets/recruit/zgz_neiye@3x.png" />
          <img class="id-img" v-if="styleType == 'zcz'" src="../../assets/recruit/zhicheng@3x.png" />
        </template>
        <div class="id-text" v-if="styleType == 'IDCardA'">上传正面</div>
        <div class="id-text" v-if="styleType == 'IDCardB'">上传反面</div>
        <div class="id-text" v-if="styleType == 'doctorThumb' || styleType == 'zcz'">上传照片</div>
        <div class="id-text" v-if="styleType == 'zyzA' || styleType == 'yszA'">上传首页</div>
        <div class="id-text" v-if="styleType == 'zyzB' || styleType == 'yszB'">上传内页</div>
      </div>
      <!-- <div v-if="value" class="image">
        <el-image :src="value" :style="`width:150px;height:150px;`" fit="fill" />
        <div class="mask">
          <div class="actions">
            <span title="预览" @click.stop="dialogVisible = true">
              <i class="el-icon-zoom-in" />
            </span>
            <span title="移除" @click.stop="removeImage">
              <i class="el-icon-delete" />
            </span>
          </div>
        </div>
      </div> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body>
      <img :src="value" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script>
import { ossUpload } from "@/utils/alioss.js";
import { getChooseImage, setChooseImage, removeChooseImage } from "@/store/cookie";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  ...mapActions(["changeUplodaStateData"]),
  props: {
    dataValue: {
      type: String,
      required: true,
      default: "",
    },
    styleType: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      headers: {
        // Authorization: getToken(),
      },
      Addfrom: {
        url: "",
      },
      value: this.dataValue,
      disabled: this.getDisibleState,
      // getChooseImage() ? false : true
    };
  },
  computed: {
    getDisibleState() {
      return this.$store.state.uplodaState;
    },
  },
  watch: {
    dataValue: {
      handler(n, o) {
        console.log("dataValue：", n, o);
        this.value = n;
      },
      deep: true,
    },
    getDisibleState: {
      handler(n, o) {
        this.disabled = n;
        console.log("...uplodaState:", n);
      }
    },
  },
  methods: {
    handleUpload() {
      this.disabled = false;
      setTimeout(() => {
        document.querySelector(`.upload-resource .el-upload`).click();
      }, 100);
    },
    httpRequest({ file }) {
      console.log("....httpRequest", file);
      // setChooseImage(true);
      //阿里云OSS上传
      console.log(">>>?>???", file);
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10M!");
        return;
      }
      ossUpload({}, file).then((res) => {
        let fileList = res;
        this.value = fileList.url;
        this.disabled = false;
        console.log("D>DDWQEWEQW", this.value);
        this.$emit("changeFile", fileList.url, this.styleType);
      });
    },
    removeImage() {
      this.value = "";
      this.$emit("changeFile", this.value);
    },
    handleUploadSuccess(res) {
      console.log("....handleUploadSuccess", res);
      this.$emit("input", res.url);
      // this.loading.close();
    },
    uplodaClick() {
      const self = this;
      if (this.disabled) {
        this.disabled = getChooseImage() ? false : true;
      }
      console.log(".....ddd", getChooseImage(), this.disabled);
      if (!getChooseImage()) {
        this.$confirm("请允许APP访问您的相机、相册、存储权限，便于您使用该功能上传病例图片或意见反馈图片等场景中读取和写入相册和文件内容", "提示", {
          confirmButtonText: "允许",
          cancelButtonText: "不允许",
          customClass: "customClassConfirm",
          type: "warning",
          showClose: false,
          closeOnClickModal: false,
        })
          .then(() => {
            setChooseImage(true);
            self.handleUpload();
            this.$store.dispatch("changeUplodaStateData", false);
          })
          .catch(() => {});
      } else {
      }
    },
    handleBeforeUpload(handleBeforeUpload) {
      console.log("....handleBeforeUpload", handleBeforeUpload);
    },
    handleUploadError(handleUploadError) {
      console.log("....handleUploadError", handleUploadError);
      this.$message({
        type: "error",
        message: "上传失败",
      });
      // this.loading.close();
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.component-upload-image {
  .IDCardA,
  .IDCardB,
  .zyzA,
  .zyzB,
  .yszA,
  .yszB {
    .image-slot {
      padding-top: 20px;
    }
    .el-upload {
      width: 310px !important;
      height: 240px !important;
      background: #f6f8fa;
      border-radius: 10px;
      border: none;
      .el-image {
        padding: 20px 0 0;
      }
      .id-img {
        width: 240px;
        height: 160px;
        display: inline-block;
      }
      .id-text {
        margin-top: 10px;
        display: inline-block;
        width: 310px;
        height: 50px;
        line-height: 50px;
        background: #3c81f3;
        border-radius: 0px 0px 10px 10px;
        font-weight: 500;
        font-size: 30px;
        color: #ffffff;
      }
    }
  }
  .zcz {
    .el-upload {
      width: 310px !important;
      height: 300px !important;
      background: #f6f8fa;
      border-radius: 10px;
      border: none;
      .el-image {
        padding: 20px 0 0;
      }
    }
    .id-img {
      width: 220px;
      height: 220px;
      display: inline-block;
    }
    .id-text {
      margin-top: 10px;
      display: inline-block;
      width: 310px;
      height: 50px;
      line-height: 50px;
      background: #3c81f3;
      border-radius: 0px 0px 10px 10px;
      font-weight: 500;
      font-size: 30px;
      color: #ffffff;
    }
  }
  .doctorThumb {
    .image-slot {
      padding-top: 45px;
    }
    .el-upload {
      width: 300px !important;
      height: 300px !important;
      background: #f6f8fa;
      border-radius: 10px;
      border: none;
      .el-image {
        padding: 20px 0 0;
      }
    }
    .id-img {
      width: 190px;
      height: 190px;
      display: inline-block;
    }
    .id-text {
      margin-top: 10px;
      display: inline-block;
      width: 310px;
      height: 50px;
      line-height: 50px;
      background: #3c81f3;
      border-radius: 0px 0px 10px 10px;
      font-weight: 500;
      font-size: 30px;
      color: #ffffff;
    }
  }
}
.customClassConfirm {
  width: auto !important;
  .el-message-box__container {
    .el-message-box__status {
      display: none !important;
    }
    .el-message-box__message {
      padding: 0 !important;
    }
  }
}
</style>
<style scoped lang="scss">
.image {
  position: relative;

  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
  }

  &:hover .mask {
    opacity: 1;
  }
}
</style>
